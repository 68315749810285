import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoadingSpinnerComponent } from '../utils/components/loading-spinner/loading-spinner.component';
import { LoginComponent } from './login/login.component';
import { PagesComponent } from './pages.component';
import { PAGES_ROUTES } from './pages.routes';

@NgModule({
  declarations: [
    PagesComponent,
    LoginComponent,
    LoadingSpinnerComponent
  ],
  imports: [
    PAGES_ROUTES,
    CommonModule,
  ],
  exports: [
    PagesComponent,
    LoginComponent,
    LoadingSpinnerComponent
  ]
})

export class PagesModule { }

