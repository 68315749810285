import { Routes, RouterModule } from "@angular/router";
import { PagesComponent } from './pages.component';
import { LoginComponent } from './login/login.component';
import { SecureInnerPagesGuard } from '../utils/guards/secure-inner-pages.guard';
import { AuthGuard } from '../utils/guards/auth.guard';

const pagesRoutes: Routes = [
    {
        path: "",
        component: PagesComponent,
        children: [
            { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard] },
            { path: "login", component: LoginComponent, canActivate: [SecureInnerPagesGuard] },
            { path: "", redirectTo: "/login", pathMatch: "full" },
        ]
    }

];

export const PAGES_ROUTES = RouterModule.forChild(pagesRoutes);
