export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyB_QLfzTI_SPMNRHmH3eBGV-Bo9KLG87jc",
    authDomain: "q-department-develop-718f1.firebaseapp.com",
    databaseURL: "https://q-department-develop-718f1.firebaseio.com",
    projectId: "q-department-develop-718f1",
    storageBucket: "q-department-develop-718f1.appspot.com",
    messagingSenderId: "432467411586",
    appId: "1:432467411586:web:787d64f88b63d43cc96a9b",
    measurementId: "G-5TBFBP11PT"
  }
};
