import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { SweetAlertService } from '../swal/sweetAlert.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  ref = firebase.firestore().collection('usersCollection');

  public isLogged = new BehaviorSubject(null);
  currentIsLogged = this.isLogged.asObservable();

  public userLogged = new BehaviorSubject(null);
  currentUserLogged = this.userLogged.asObservable();

  constructor(
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    public sweetAlertServices: SweetAlertService
  ) {
    this.afAuth.authState.subscribe(user => {
      localStorage.setItem("user", null);

      if (user != null) {
        this.getUser(user.uid).subscribe(async (userData) => {
          localStorage.setItem("user", JSON.stringify(userData));
          await this.changeLoginStatus(userData);
        });
      }
    });
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem("user"));
    this.changeLoginStatus(user);
    return user !== null && user.emailVerified !== false ? true : false;
  }

  changeLoginStatus(userData: any) {
    this.isLogged.next(userData)
  }


  SignIn(email, password) {
    return this.afAuth.auth
      .signInWithEmailAndPassword(email, password)
      .then(async result => {
        await this.SetUserData(result.user);
        await this.getUser(result.user.uid).subscribe(async (userData) => {
          this.changeLoginStatus(userData);
          this.router.navigate(["home"]);
        });
      })
      .catch(error => {

        if (error.code == 'auth/invalid-email') {
          this.sweetAlertServices.userWrongDataLogin().subscribe(() => { })
        }
        if (error.code == 'auth/user-not-found') {
          this.sweetAlertServices.userDontExist().subscribe(() => { })
        }
        if (error.code == 'auth/wrong-password') {
          this.sweetAlertServices.userWrongDataLogin().subscribe(() => { })
        }
      });
  }


  SignUp(email, password) {
    return this.afAuth.auth
      .createUserWithEmailAndPassword(email, password)
      .then(result => {
        this.SendVerificationMail();
        this.SetUserData(result.user);
      })
      .catch(error => {
        window.alert(error.message);
      });
  }

  SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification().then(() => {

      this.sweetAlertServices.userSendEmail().subscribe(() => {
        this.router.navigate(["login"]);
      })
    });
  }

  ForgotPassword(passwordResetEmail) {
    return this.afAuth.auth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        this.sweetAlertServices.userSendEmail().subscribe(() => {
          this.router.navigate(["login"]);

        })
      })
      .catch(error => {
        if (error.code == 'auth/invalid-email') {
          this.sweetAlertServices.userWrongDataLogin().subscribe(() => {
            this.router.navigate(["login"]);

          })
        }
        if (error.code == 'auth/user-not-found') {
          this.sweetAlertServices.userDontExist().subscribe(() => {
            this.router.navigate(["login"]);
          })
        }
      });
  }


  SetUserData(user) {
    const userCollectionReference: AngularFirestoreDocument<any> = this.afs.doc(
      `usersCollection/${user.uid}`
    );
    const userData = {
      uid: user.uid,
      email: user.email,
      emailVerified: user.emailVerified,
      lastLogin: new Date()
    };
    return userCollectionReference.set(userData, {
      merge: true
    });

  }

  SignOut() {
    return this.afAuth.auth.signOut().then(() => {
      localStorage.removeItem("user");
      // this.router.navigate(["login"]);
      window.location.href = './';

      this.changeLoginStatus(null);
    });
  }

  getUser(id: string): Observable<any> {
    return new Observable((observer) => {
      this.ref.doc(id).onSnapshot((doc) => {
        observer.next(doc.data())
      })
    })
  }
}
