import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxGalleryModule } from 'ngx-gallery-9';
import { environment } from 'src/environments/environment';
import { APP_ROUTS } from "./app-routing.module";
import { AppComponent } from './app.component';
import { PagenotfoundComponent } from './pages/page-not-found/page-not-found.component';
import { PagesModule } from './pages/pages.module';
import { MaterialModule } from './utils/components/material';

@NgModule({
  declarations: [
    AppComponent,
    PagenotfoundComponent,
  ],
  imports: [

    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    APP_ROUTS,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    PagesModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    HttpClientModule,
    NgxGalleryModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MaterialModule],
  providers: [AngularFireStorage],
  bootstrap: [AppComponent]
})
export class AppModule { }
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}