import { OnInit, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-pages',
    templateUrl: './pages.component.html',
    styles: []
})
export class PagesComponent implements OnInit {

    ngOnInit() {
    }

    constructor(
        private translate: TranslateService
    ) {
        translate.setDefaultLang('es');
    }
}