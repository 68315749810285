import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import swal from 'sweetalert';

@Injectable({
    providedIn: "root"
})

export class SweetAlertService {

    constructor(
        private translate: TranslateService,
    ) { }

    customMessageAlert(message) {
        return this.translate.get('swal.alert.title').subscribe((title) => {
            swal({
                title: title,
                text: message,
                icon: "warning",
                timer: 30000,
                buttons: {
                    cancel: false,
                    confirm: true,
                },
            });
        })
    }

    resetDate(): Observable<any> {
        return new Observable((observer) => {
            this.translate.get('swal.resetDate.title').subscribe((title) => {
                this.translate.get('swal.resetDate.message').subscribe((message) => {
                    this.translate.get('swal.resetDate.button').subscribe((button) => {
                        swal(title, message, "warning", {
                            buttons: [button.cancel, button.confirm],
                            dangerMode: false,
                        }).then((willDelete) => {
                            observer.next(willDelete);
                        });
                    });
                });
            });
        });
    }

    noSelectDate() {
        return this.translate.get('swal.noSelectDate.title').subscribe((title) => {
            this.translate.get('swal.noSelectDate.message').subscribe((message) => {
                this.translate.get('swal.noSelectDate.button').subscribe((button) => {
                    swal({
                        title: title,
                        text: message,
                        icon: "warning",
                        timer: 500,
                        buttons: [false, button.confirm],
                    });
                });
            })
        })
    }

    enterVisitorAlert() {
        return this.translate.get('swal.enterVisitor.title').subscribe((title) => {
            this.translate.get('swal.enterVisitor.message').subscribe((message) => {
                swal({
                    title: title,
                    text: message,
                    icon: "success",
                    timer: 1000,
                    buttons: {
                        cancel: false,
                        confirm: false,
                    },
                });
            })
        })
    }

    saveAlert() {
        return this.translate.get('swal.save.title').subscribe((title) => {
            this.translate.get('swal.save.message').subscribe((message) => {
                swal({
                    title: title,
                    text: message,
                    icon: "success",
                    timer: 500,
                    buttons: {
                        cancel: false,
                        confirm: false,
                    },
                });
            })
        })
    }

    updateAlert() {
        return this.translate.get('swal.update.title').subscribe((title) => {
            this.translate.get('swal.update.message').subscribe((message) => {
                swal({
                    title: title,
                    text: message,
                    icon: "success",
                    timer: 500,
                    buttons: {
                        cancel: false,
                        confirm: false,
                    },
                });
            })
        })
    }

    noUpdateAlert() {
        return this.translate.get('swal.noUpdate.title').subscribe((title) => {
            this.translate.get('swal.noUpdate.message').subscribe((message) => {
                swal({
                    title: title,
                    text: message,
                    icon: "warning",
                    timer: 30000,
                    buttons: {
                        cancel: false,
                        confirm: true,
                    },
                });
            })
        })
    }

    addVisitorAlert() {
        return this.translate.get('swal.addVisitor.title').subscribe((title) => {
            this.translate.get('swal.addVisitor.message').subscribe((message) => {
                swal({
                    title: title,
                    text: message,
                    icon: "success",
                    timer: 500,
                    buttons: {
                        cancel: false,
                        confirm: false,
                    },
                });
            })
        })
    }

    removeVisitorAlert(): Observable<any> {
        return new Observable((observer) => {
            this.translate.get('swal.removeVisitor.title').subscribe((title) => {
                this.translate.get('swal.removeVisitor.message').subscribe((message) => {
                    this.translate.get('swal.removeVisitor.button').subscribe((button) => {
                        swal(title, message, "error", {
                            buttons: [button.cancel, button.confirm],
                            dangerMode: true,
                        }).then((willDelete) => {
                            observer.next(willDelete);
                        });
                    });
                });
            });
        });
    }

    successRemoveAlert() {
        return this.translate.get('swal.removeVisitor.success.title').subscribe((deletedTitle) => {
            this.translate.get('swal.removeVisitor.success.message').subscribe((deletedMessage) => {
                swal(deletedTitle, deletedMessage, "success");
            });
        });
    }

    deleteAlert(): Observable<any> {
        return new Observable((observer) => {
            this.translate.get('swal.delete.title').subscribe((title) => {
                this.translate.get('swal.delete.message').subscribe((message) => {
                    this.translate.get('swal.delete.button').subscribe((button) => {
                        swal(title, message, "error", {
                            buttons: [button.cancel, button.confirm],
                            dangerMode: true,
                        }).then((willDelete) => {
                            observer.next(willDelete);
                        });
                    });
                });
            });
        });
    }

    successDeletedAlert() {
        return this.translate.get('swal.deleted.title').subscribe((deletedTitle) => {
            this.translate.get('swal.deleted.message').subscribe((deletedMessage) => {
                swal(deletedTitle, deletedMessage, "success");
            });
        });
    }

    uploadLogo() {
        return this.translate.get('swal.uploadLogo.title').subscribe((title) => {
            this.translate.get('swal.uploadLogo.message').subscribe((message) => {
                swal(title, message, "warning");
            })
        })
    }

    uploadPhoto() {
        return this.translate.get('swal.uploadPhoto.title').subscribe((title) => {
            this.translate.get('swal.uploadPhoto.message').subscribe((message) => {
                swal(title, message, "warning");
            })
        })
    }

    uploadEvidence() {
        return this.translate.get('swal.uploadEvidence.title').subscribe((title) => {
            this.translate.get('swal.uploadEvidence.message').subscribe((message) => {
                swal(title, message, "warning");
            })
        })
    }

    userDontExist(): Observable<any> {
        return new Observable((observer) => {
            this.translate.get('swal.userDontExist.title').subscribe((title) => {
                this.translate.get('swal.userDontExist.message').subscribe((message) => {
                    this.translate.get('swal.userDontExist.button').subscribe((button) => {
                        swal(title, message, "error", {
                            buttons: {
                                cancel: false,
                                confirm: {
                                    text: button,
                                },
                            }, dangerMode: false,
                        }).then((willConfirm) => {
                            observer.next(willConfirm);
                        });
                    });
                });
            });
        });
    }

    userWrongDataLogin(): Observable<any> {
        return new Observable((observer) => {
            this.translate.get('swal.userWrongDataLogin.title').subscribe((title) => {
                this.translate.get('swal.userWrongDataLogin.message').subscribe((message) => {
                    this.translate.get('swal.userWrongDataLogin.button').subscribe((button) => {
                        swal(title, message, "warning", {
                            buttons: {
                                cancel: false,
                                confirm: {
                                    text: button,
                                },
                            }, dangerMode: false,
                        }).then((willConfirm) => {
                            observer.next(willConfirm);
                        });
                    });
                });
            });
        });
    }

    userSendEmail(): Observable<any> {
        return new Observable((observer) => {
            this.translate.get('swal.userSendEmail.title').subscribe((title) => {
                this.translate.get('swal.userSendEmail.message').subscribe((message) => {
                    this.translate.get('swal.userSendEmail.button').subscribe((button) => {
                        swal(title, message, "success", {
                            buttons: {
                                cancel: false,
                                confirm: {
                                    text: button,
                                },
                            }, dangerMode: false,
                        }).then((willConfirm) => {
                            observer.next(willConfirm);
                        });
                    });
                });
            });
        });
    }

    userIsCreated(): Observable<any> {
        return new Observable((observer) => {
            this.translate.get('swal.userIsCreated.title').subscribe((title) => {
                this.translate.get('swal.userIsCreated.message').subscribe((message) => {
                    this.translate.get('swal.userIsCreated.button').subscribe((button) => {
                        swal(title, message, "warning", {
                            buttons: {
                                cancel: false,
                                confirm: {
                                    text: button,
                                },
                            }, dangerMode: false,
                        }).then((willConfirm) => {
                            observer.next(willConfirm);
                        });
                    });
                });
            });
        });
    }

    alertNeedToLoginWithNewUser() {
        // TRADUCTIONS
        return new Observable((observer) => {
            this.translate.get('swal.NeedToLogin.title').subscribe((title) => {
                this.translate.get('swal.NeedToLogin.message').subscribe((message) => {
                    this.translate.get('swal.NeedToLogin.button').subscribe((button) => {

                        swal(title, message, "success", {
                            buttons: {
                                cancel: false,
                                confirm: {
                                    text: button,
                                },
                            },
                            dangerMode: false,
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        }).then((willConfirm) => {
                            observer.next(willConfirm);
                        });
                    });
                });
            });
        });
    }

    userUnverifiedEmail() {
        // TRADUCCTIONES
        return new Observable((observer) => {
            this.translate.get('swal.userUnverifiedEmail.title').subscribe((title) => {
                this.translate.get('swal.userUnverifiedEmail.message').subscribe((message) => {
                    this.translate.get('swal.userUnverifiedEmail.button').subscribe((button) => {

                        swal(title, message, "warning", {
                            buttons: {
                                cancel: false,
                                confirm: {
                                    text: button,
                                },
                            },
                            dangerMode: false,
                            closeOnClickOutside: false,
                            closeOnEsc: false,
                        }).then((willConfirm) => {
                            observer.next(willConfirm);
                        });
                    });
                });
            });
        });
    }

    confirmAlert(): Observable<any> {
        return new Observable((observer) => {
            this.translate.get('swal.confirm.title').subscribe((title) => {
                this.translate.get('swal.confirm.message').subscribe((message) => {
                    this.translate.get('swal.confirm.button').subscribe((button) => {
                        swal(title, message, "warning", {
                            buttons: [button.cancel, button.confirm],
                            dangerMode: false,
                        }).then((willConfirm) => {
                            observer.next(willConfirm);
                        });
                    });
                });
            });
        });
    }

    wrongDocument() {
        this.translate.get('swal.error.wrongDocument').subscribe((wrongDocument) => {
            swal('ERROR', wrongDocument, 'error')
        })
    }

    massiveImport() {
        return new Observable((observer) => {
            var loading = document.getElementById("mat");
            this.translate.get('swal.massiveImport').subscribe((massiveImport) => {
                swal(massiveImport.title, massiveImport.message, 'warning', {
                    content: {
                        element: loading
                    },
                    buttons: {
                        cancel: massiveImport.button.cancel,
                        confirm: {
                            text: massiveImport.button.confirm,
                        },
                    },
                }).then(isWaiting => {
                    observer.next(isWaiting);
                }).catch((err) => {
                    console.log(err);
                });
            });
        });
    }

    confirmImportAlert(): Observable<any> {
        return new Observable((observer) => {
            this.translate.get('swal.confirmImport.title').subscribe((title) => {
                this.translate.get('swal.confirmImport.message').subscribe((message) => {
                    this.translate.get('swal.confirmImport.button').subscribe((button) => {
                        swal(title, message, "warning", {
                            buttons: [button.cancel, button.confirm],
                            dangerMode: false,
                        }).then((willConfirm) => {
                            observer.next(willConfirm);
                        });
                    });
                });
            });
        });
    }

}